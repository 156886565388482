import React, { createContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LoadingSpinner } from '../components/LoadingSpinner'
import { goTo, LOGIN } from '../util/goTo'
import { authAxios, getToken, refreshToken, setToken } from '../util/api'
import { decodeState } from '../util/decoder'
import { connect, disconnect } from '../util/socketClient'
import { User } from '../types/user'
import { io, Socket } from 'socket.io-client'
import { CookieNames } from '../types/cookieNames'
import { UserProperty, UserPropertyResponse } from '../types/userProperty'

type UserProperties = {
  phoneNumber: string | null
  telegramEnabled: boolean | null
}

export const AuthContext = createContext<{
  user: User | undefined
  logout: (pathname?: string) => void
  refreshToken: () => Promise<string>
  accessToken: string
  socketIO: Socket | undefined
  webSocketConnected: boolean
  webSocketIOConnected: boolean
  userProperties: UserProperties
  refetchUserProperties: () => Promise<UserProperties>
}>({
  user: undefined,
  logout: () => {},
  refreshToken: () => Promise.resolve(''),
  accessToken: '',
  socketIO: undefined,
  webSocketConnected: false,
  webSocketIOConnected: false,
  userProperties: { phoneNumber: null, telegramEnabled: null },
  refetchUserProperties: () => Promise.resolve({ phoneNumber: null, telegramEnabled: null })
})
AuthContext.displayName = 'AuthContext'

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [user, setUser] = useState<User | undefined>()
  const [userProperties, setUserProperties] = useState<UserProperties>({ phoneNumber: null, telegramEnabled: null })
  const [accessToken, setAccessToken] = useState<string>()
  const [webSocketConnected, setWebSocketConnected] = useState(false)
  const [webSocketIOConnected, setWebSocketIOConnected] = useState(false)
  const [socketIO, setSocketIO] = useState<Socket>()

  useEffect(() => {
    if (accessToken) {
      setSocketIO(
        io({
          path: '/provider/socket.io/',
          auth: { token: accessToken },
          reconnectionDelay: 2000,
          reconnectionDelayMax: 15000
        })
      )
    }
  }, [accessToken])

  useEffect(() => {
    if (socketIO) {
      const onConnect = () => {
        if (socketIO) {
          setWebSocketIOConnected(true)
          socketIO.emit('authenticated')
        }
      }

      const onDisconnect = () => setWebSocketIOConnected(false)

      const onConnectError = (err: Error) => {
        console.error('Socket.io: Connection error:', err.message)
      }

      socketIO.on('connect', onConnect)
      socketIO.on('disconnect', onDisconnect)
      socketIO.on('connect_error', onConnectError)

      return () => {
        socketIO.off('connect', onConnect)
        socketIO.off('disconnect', onDisconnect)
        socketIO.off('connect_error', onConnectError)
        socketIO.removeAllListeners()
        socketIO.close()
      }
    }
  }, [socketIO])

  const onConnected = () => setWebSocketConnected(true)

  const onDisconnected = () => setWebSocketConnected(false)

  const logout = (pathname = '/') => {
    authAxios()
      .get('/api/logout')
      .then(() => goTo({ pathname }))
  }

  useEffect(() => {
    if (!!getToken() && !webSocketConnected) {
      const refreshTokenInterval = setInterval(
        () => refreshToken().then(accessToken => setAccessToken(accessToken)),
        10000 // 10 seconds
      )
      const interval = setInterval(
        () => connect(getToken(), onConnected, onDisconnected),
        11000 // 11 seconds
      )
      return () => {
        clearInterval(interval)
        clearInterval(refreshTokenInterval)
      }
    }
  }, [webSocketConnected])

  const getUserProperties = async () => {
    const axios = authAxios()

    const [phoneNumberRes, telegramEnabledRes] = await Promise.allSettled([
      axios.get<UserPropertyResponse>(`/api/userproperty/${UserProperty.PHONE_NUMBER}`).then(({ data }) => data.value),
      axios.get<{ enabled: boolean }>('/api/telegram/enabled').then(({ data }) => data.enabled)
    ])
    const userProperties = {
      phoneNumber: phoneNumberRes.status === 'fulfilled' ? phoneNumberRes.value : null,
      telegramEnabled: telegramEnabledRes.status === 'fulfilled' ? telegramEnabledRes.value : null
    }
    setUserProperties(userProperties)

    return userProperties
  }

  const getUser = async () => {
    const axios = authAxios()

    const [user] = await Promise.all([
      axios.get<User>('/api/users/current').then(({ data }) => data),
      getUserProperties()
    ])
    setUser(user)

    return user
  }

  useEffect(() => {
    if (!getToken()) {
      if (searchParams.get('state')) {
        const state = decodeState(searchParams.get('state'))
        if (state?.token) {
          setToken(state.token)
          setAccessToken(state.token)

          getUser()
            .then(() => {
              searchParams.delete('state')
              setSearchParams(searchParams, { replace: true })
              connect(state.token, onConnected, onDisconnected)

              if (
                searchParams.has('code') &&
                [
                  'facebookConnect',
                  'instagramConnect',
                  'googleConnect',
                  'tiktokConnectBusiness',
                  'tiktokConnectCreator'
                ].includes(state.action)
              ) {
                const code = searchParams.get('code')
                navigate(`${state.pathname}?code=${code}&action=${state.action}`)
              } else {
                document.cookie = `${CookieNames.ASK_PHONE_NUMBER}=true;path=/` //ask phone number after login
              }
            })
            .catch(() => {
              goTo({ pathname: LOGIN })
            })

          return () => disconnect(onDisconnected)
        } else {
          goTo({ pathname: LOGIN })
        }
      } else {
        refreshToken().then(accessToken => {
          connect(accessToken, onConnected, onDisconnected)
          setAccessToken(accessToken)
          getUser().catch(() => goTo({ pathname: LOGIN }))
        })
        return () => disconnect(onDisconnected)
      }
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user,
        logout,
        accessToken,
        socketIO,
        webSocketConnected,
        webSocketIOConnected,
        refreshToken: () =>
          refreshToken().then(accessToken => {
            setAccessToken(accessToken)
            return accessToken
          }),
        userProperties,
        refetchUserProperties: getUserProperties
      }}
    >
      {user?.userId ? children : <LoadingSpinner />}
    </AuthContext.Provider>
  )
}
