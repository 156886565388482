import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
  AppConfigProvider,
  AuthProvider,
  BalanceProvider,
  ErrorBoundary,
  I18nProvider,
  SystemMenuProvider,
  NotificationProvider,
  RestErrorHandlingProvider,
  SubscriptionProvider,
  WaitingSpinnerProvider,
  LoadingSpinner
} from '@adverton/common'
import { WrappedWelcomePage } from './WrappedWelcomePage'
import { WelcomePage } from './WelcomePage'

export const Welcome = () => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios
      .post('/api/auth/refreshToken')
      .then(({ data }) => {
        axios
          .get('/api/users/current', { headers: { Authorization: `Bearer ${data?.accessToken}` } })
          .then(({ data: userData }) => setUser(userData))
          .finally(() => setLoading(false))
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  if (loading) return <LoadingSpinner />

  return (
    <>
      {user && (
        <ErrorBoundary>
          <AuthProvider>
            <AppConfigProvider>
              <SystemMenuProvider>
                <I18nProvider url="/api/i18n/site">
                  <RestErrorHandlingProvider>
                    <WaitingSpinnerProvider>
                      <SubscriptionProvider>
                        <BalanceProvider>
                          <NotificationProvider>
                            <WrappedWelcomePage />
                          </NotificationProvider>
                        </BalanceProvider>
                      </SubscriptionProvider>
                    </WaitingSpinnerProvider>
                  </RestErrorHandlingProvider>
                </I18nProvider>
              </SystemMenuProvider>
            </AppConfigProvider>
          </AuthProvider>
        </ErrorBoundary>
      )}
      {!user && <WelcomePage />}
    </>
  )
}
