import { useMatch, useNavigate } from 'react-router-dom'
import React, { FC, HTMLAttributes, useContext } from 'react'
import { SystemMenuContext } from '../providers/SystemMenuProvider'
import { Badge, Col, Row } from 'react-bootstrap'
import { I18nContext } from '../providers/I18nProvider'
import { goTo } from '../util/goTo'
import { AdsIcon } from './icons/AdsIcon'
import { SiteIcon } from './icons/SiteIcon'
import { CrmIcon } from './icons/CrmIcon'
import { SupportIcon } from './icons/SupportIcon'
import { AppConfigContext } from '../providers/AppConfigProvider'
import { HomeIcon } from './icons/HomeIcon'

export const SystemMenu = ({ siteEditorEnabled = false }) => {
  const navigate = useNavigate()
  const { crmEnabled } = useContext(AppConfigContext)
  const { crmOrdersCount } = useContext(SystemMenuContext)
  const isCrmModule = window.location.pathname.startsWith('/adv/crm')
  const isAdvModule = window.location.pathname.startsWith('/adv') && !isCrmModule
  const isSiteEditorModule = window.location.pathname.startsWith('/site-editor')
  const isHomePage = useMatch('/')

  const onAdsClick = () => {
    goTo({ pathname: '/adv' })
  }

  const onSiteClick = () => {
    if (isSiteEditorModule) {
      if (!isHomePage) {
        navigate('/')
      }
    } else {
      goTo({ pathname: '/site-editor' })
    }
  }

  const onCrmClick = () => {
    if (isAdvModule) {
      navigate('/crm')
    } else {
      goTo({ pathname: '/adv/crm' })
    }
  }

  return (
    <div className="px-2 py-1 common-bg">
      <Row className="justify-content-around gx-3">
        <SystemMenuButton
          name="welcome"
          icon={HomeIcon}
          onClick={() => goTo({ pathname: '/' })}
          active={window.location.pathname === '/'}
        />
        <SystemMenuButton name="ads" icon={AdsIcon} onClick={onAdsClick} active={isAdvModule} />
        <SystemMenuButton
          name="site"
          icon={SiteIcon}
          onClick={siteEditorEnabled ? onSiteClick : null}
          active={isSiteEditorModule}
        />
        <SystemMenuButton
          name="crm"
          icon={CrmIcon}
          onClick={crmEnabled ? onCrmClick : null}
          count={crmOrdersCount}
          active={isCrmModule}
        />
        <SystemMenuButton name="support" icon={SupportIcon} id="intercom-custom-launcher" />
      </Row>
    </div>
  )
}

type SystemMenuButtonProps = HTMLAttributes<HTMLDivElement> & {
  name: string
  icon: FC<{ width: number; height: number; className: string; active: boolean }>
  onClick?: () => void
  count?: number
  disabled?: boolean
  active?: boolean
}
const SystemMenuButton = ({ active, name, icon: Icon, onClick, count, disabled, ...props }: SystemMenuButtonProps) => {
  const { translate } = useContext(I18nContext)

  return (
    <Col
      xs="auto"
      className={`text-center position-relative ${disabled ? 'opacity-50' : ''}`}
      role={disabled ? '' : 'button'}
      onClick={onClick}
      {...props}
    >
      <Badge
        pill
        hidden={!count || count === 0}
        bg="danger"
        className="position-absolute top-0 start-100 translate-middle z-2"
      >
        {count < 100 ? count : '99+'}
      </Badge>
      <div className={`common-menu-icon ${active ? 'active' : ''}`}>
        <Icon width={30} height={30} className="common-no-shaking" active={active} />
        <small className="d-block common-font-0_725em common-line-height-175">{translate(`systemMenu.${name}`)}</small>
      </div>
    </Col>
  )
}
