import { Currency } from './currency'
import { ShopPlatform } from 'adv/src/util/constants/shopPlatform'
import { PaymentProvider } from './paymentProvider'

export type AppConfig = {
  homeCountry: string
  campaignsCurrency: Currency
  paymentCurrency: Currency
  textGeneratorEnabled: boolean
  version: string
  allowedShops: ShopPlatform[]
  siteEditorEnabled: boolean
  crmEnabled: boolean
  paymentProvider?: PaymentProvider
  siteProviderURIPostfix: string
  domainBaseName: string
  userTelegramEnabled: boolean
  whatsappNotificationEnabled: boolean
  knowledgeBaseUrl: string
  mixPanelProjectToken: string
  multiLanguageTargetingEnabled: boolean
}

export const DEFAULT_APP_CONFIG = {
  homeCountry: '',
  campaignsCurrency: Currency.USD,
  paymentCurrency: Currency.KZT,
  textGeneratorEnabled: false,
  version: '',
  allowedShops: [],
  siteEditorEnabled: false,
  crmEnabled: false,
  siteProviderURIPostfix: '',
  domainBaseName: '',
  userTelegramEnabled: false,
  whatsappNotificationEnabled: false,
  knowledgeBaseUrl: '',
  mixPanelProjectToken: '',
  multiLanguageTargetingEnabled: false
}
