export const LOGIN = '/login'

export const goTo = ({ origin = window.location.origin, pathname = '', searchParams = {}, newTab = false }) => {
  const _url = new URL(origin + pathname)
  Object.entries(searchParams).forEach(([key, value]) => _url.searchParams.append(key, value?.toString()))

  if (newTab) {
    window.open(_url.toString(), '_blank').focus()
  } else {
    window.location.href = _url.toString()
  }
}
