import React from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'

export const LoadingSpinner: React.FC<{ hidden?: boolean; text?: string }> = ({
  text,
  hidden = false
}: {
  hidden?: boolean
  text?: string
}) => {
  if (hidden) {
    return undefined
  }

  return (
    <Row className="w-100 m-0 p-0 min-vh-100 common-z-index-1046 position-fixed top-0 start-0">
      <Col sm={0} md={2} lg={3} xl={3} xxl={4} className="p-0" />
      <Col sm={12} md={8} lg={6} xl={6} xxl={4} className="p-0">
        <Container className="min-vh-100 d-flex flex-column justify-content-center common-bg">
          <Spinner animation="border" className="align-self-center" />
          <p hidden={!text} className="text-center pt-3">
            {text}
          </p>
        </Container>
      </Col>
      <Col sm={0} md={2} lg={3} xl={3} xxl={4} className="p-0" />
    </Row>
  )
}
