import React, { createContext, useContext, useEffect, useState } from 'react'
import { Badge, Button, Fade, Spinner, ToastContainer } from 'react-bootstrap'
import { useNotifications } from '../hooks/webSockets/useNotifications'
import { Notification } from '../components/Notification'
import { I18nContext } from './I18nProvider'
import { ReactComponent as CheckCircle } from 'bootstrap-icons/icons/check-circle.svg'
import { AuthContext } from './AuthProvider'

export const NotificationContext = createContext<{
  unreadNotificationsCount: number
  decrementUnreadUCount: () => void
  showSavedAlert: () => void
}>({
  unreadNotificationsCount: 0,
  decrementUnreadUCount: () => {},
  showSavedAlert: () => {}
})
NotificationContext.displayName = 'NotificationContext'

const CONNECTION_LOST_ALERT_DELAY_SEC = 35
export const NotificationProvider = ({ children }) => {
  const { webSocketConnected, webSocketIOConnected } = useContext(AuthContext)
  const { translate } = useContext(I18nContext)
  const [showShowSavedAlert, setShowShowSavedAlert] = useState(false)
  const [showConnectionLostAlert, setShowConnectionLostAlert] = useState(false)
  const { notifications, setNotifications, unreadNotificationsCount, decrementUnreadUCount } = useNotifications()

  useEffect(() => {
    if (!webSocketConnected || !webSocketIOConnected) {
      const timeout = setTimeout(() => setShowConnectionLostAlert(true), CONNECTION_LOST_ALERT_DELAY_SEC * 1000)

      return () => clearTimeout(timeout)
    } else {
      setShowConnectionLostAlert(false)
    }
  }, [webSocketConnected, webSocketIOConnected])

  const showSavedAlert = () =>
    setTimeout(() => {
      if (!showShowSavedAlert) {
        setShowShowSavedAlert(true)
        setTimeout(() => setShowShowSavedAlert(false), 1500)
      }
    }, 300)

  const showCloseAll = notifications.length > 1
  return (
    <NotificationContext.Provider value={{ unreadNotificationsCount, decrementUnreadUCount, showSavedAlert }}>
      {children}
      <Fade in={showShowSavedAlert} mountOnEnter unmountOnExit>
        <h2 className="position-fixed top-0 start-50 translate-middle-x common-z-index-1046 mt-1 common-font-1_6em">
          <Badge bg="success" pill>
            <span className="fw-light">{translate('notifications.published')}</span>
            <CheckCircle width="20" height="20" fill="white" className="ms-2" />
          </Badge>
        </h2>
      </Fade>
      <Fade in={showConnectionLostAlert} mountOnEnter unmountOnExit>
        <h3 className="position-fixed top-0 start-50 translate-middle-x common-z-index-1046 mt-1">
          <Badge bg="warning" pill className="fw-light d-flex align-items-center gap-2">
            <span>{translate('notifications.reconnection')}</span>
            <Spinner animation="border" size="sm" />
          </Badge>
        </h3>
      </Fade>
      <ToastContainer
        className={`d-flex flex-column align-items-end position-fixed top-0 ${
          showCloseAll ? 'pt-4' : 'common-pt-4rem'
        } pe-2 pb-4 w-100 vh-100 overflow-auto`}
        style={{ zIndex: 999 }}
      >
        <Button
          variant="link"
          className="pe-auto text-dark fw-semibold lh-lg pb-0 pt-2 border-0"
          onClick={() => setNotifications([])}
          hidden={!showCloseAll}
        >
          {translate('notifications.closeAll')}
        </Button>
        {notifications?.map((notification, index) => (
          <Notification
            key={notification?.notificationId || notification.text}
            notification={notification}
            onClose={() => setNotifications(notifications.filter((_, i) => i !== index))}
            className="common-max-vw-95"
          />
        ))}
      </ToastContainer>
    </NotificationContext.Provider>
  )
}
