import React, { createContext, Fragment, ReactNode, useContext, useMemo, useState } from 'react'
import { Alert, Spinner } from 'react-bootstrap'
import { I18nContext } from './I18nProvider'

export const WaitingSpinnerContext = createContext<{
  wait: (waiting: Waiting) => void
  go: (waiting: Waiting) => void
}>({
  wait: () => {},
  go: () => {}
})
WaitingSpinnerContext.displayName = 'WaitingSpinnerContext'

export class Waiting {
  id = ''
  timout: NodeJS.Timeout | undefined

  constructor(prefix: string) {
    this.setId(prefix)
  }

  start(callback: () => void): Waiting {
    this.timout = setTimeout(callback, 5000)
    return this
  }

  stop(callback: () => void) {
    callback()
    clearTimeout(this.timout)
  }

  generateId() {
    return (Math.random() + 1).toString(36).substring(7)
  }

  setId(prefix: string) {
    this.id = (prefix ? prefix + '_' : '') + this.generateId()
  }
}

type PropTypes = {
  children: ReactNode
}
export const WaitingSpinnerProvider = ({ children }: PropTypes) => {
  const { translate } = useContext(I18nContext)
  const [waitingReasons, setWaitingReasons] = useState<{ [id: string]: Waiting }>({})
  const [showTimeoutWarning, setShowTimeoutWarning] = useState(false)
  const [isOffline, setIsOffline] = useState(false)

  const handleOnline = () => setIsOffline(false)
  const handleOffline = () => setIsOffline(true)

  useMemo(() => {
    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  const wait = (waiting: Waiting) =>
    setWaitingReasons(reasons => ({
      ...reasons,
      [waiting.id]: waiting.start(() => setShowTimeoutWarning(true))
    }))

  const go = (waiting: Waiting) =>
    setWaitingReasons(reasons => {
      waiting.stop(() => setShowTimeoutWarning(false))
      const _reasons = { ...reasons }
      delete _reasons[waiting.id]
      return _reasons
    })

  return (
    <WaitingSpinnerContext.Provider value={{ wait, go }}>
      {children}
      {(Object.keys(waitingReasons).length !== 0 || isOffline) && (
        <WaitingSpinner
          label={
            showTimeoutWarning || isOffline
              ? isOffline
                ? translate('notifications.notification.noInternet')
                : translate('notifications.notification.timeout')
              : undefined
          }
        />
      )}
    </WaitingSpinnerContext.Provider>
  )
}

export const WaitingSpinner = ({ label, children }: { label?: string; children?: ReactNode }) => (
  <Fragment>
    <div className="modal-backdrop show d-flex justify-content-center" style={{ zIndex: 9999 }}>
      <Spinner
        animation="border"
        className="align-self-center"
        style={{ borderColor: 'cyan', borderRightColor: 'transparent' }}
      />
    </div>
    {label && (
      <div
        className="modal-backdrop show d-flex justify-content-center"
        style={{
          opacity: 100,
          zIndex: 10000,
          backgroundColor: '#ffffff00'
        }}
      >
        <Alert
          variant="secondary"
          className="common-max-vw-95 align-self-center text-center"
          style={{ height: 'fit-content' }}
        >
          <Alert.Heading className="text-center">
            <Spinner animation="border" />
          </Alert.Heading>
          <p>{label}</p>

          {children}
        </Alert>
      </div>
    )}
  </Fragment>
)
